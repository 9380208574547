import React, { useState, useEffect } from 'react';

const Input = ({
                   label,
                   error,
                   register,
                   placeholder,
                   type = "text",
                   required = true
               }) => (
    <div className="mb-6">
        <label className="block text-gray-800 font-bold mb-2 text-right">
            {label}
            {required && <span className="text-red-500 mr-1">*</span>}
        </label>
        <input
            type={type}
            {...register}
            placeholder={placeholder}
            className={`w-full p-4 border rounded-lg transition-all duration-300 placeholder-gray-400 bg-white/80
                ${error
                ? 'border-red-500 focus:ring-2 focus:ring-red-200'
                : 'border-[#c9a049]/20 focus:border-[#c9a049] focus:ring-2 focus:ring-[#c9a049]/20'
            } hover:border-[#c9a049]/40`}
            dir="rtl"
        />
        {error && (
            <p className="mt-2 text-sm text-red-500 text-right">{error}</p>
        )}
    </div>
);

const PersonalCustomization = () => {
    const [formData, setFormData] = useState({
        name: '',
        phone: '',
        description: ''
    });
    const [errors, setErrors] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [touched, setTouched] = useState({});

    useEffect(() => {
        if (Object.keys(touched).length > 0) {
            validateForm();
        }
    }, [formData, touched]);

    const validateForm = () => {
        const newErrors = {};

        if (touched.name && !formData.name.trim()) {
            newErrors.name = 'שם הוא שדה חובה';
        }

        if (touched.phone) {
            const phoneRegex = /^([0-9]{9,10})$/;
            if (!formData.phone) {
                newErrors.phone = 'מספר טלפון הוא שדה חובה';
            } else if (!phoneRegex.test(formData.phone)) {
                newErrors.phone = 'מספר טלפון לא תקין';
            }
        }

        if (touched.description && !formData.description.trim()) {
            newErrors.description = 'תיאור הבקשה הוא שדה חובה';
        }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const handleBlur = (field) => {
        setTouched(prev => ({
            ...prev,
            [field]: true
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const allTouched = Object.keys(formData).reduce((acc, key) => ({
            ...acc,
            [key]: true
        }), {});
        setTouched(allTouched);

        if (!validateForm()) {
            return;
        }

        setIsSubmitting(true);

        try {
            const businessWhatsAppNumber = '+972529022532';
            const businessEmail = 'bwdwtz417@gmail.com';

            const message = `
שם המזמין: ${formData.name}
מספר טלפון: ${formData.phone}
פרטי הבקשה: ${formData.description}
            `;

            if (window.matchMedia("(max-width: 767px)").matches) {
                const whatsappUrl = `https://wa.me/${businessWhatsAppNumber}?text=${encodeURIComponent(message)}`;
                window.open(whatsappUrl, '_blank');
            } else {
                const subject = 'בקשה חדשה מהאתר';
                const gmailUrl = `https://mail.google.com/mail/u/0/?view=cm&fs=1&to=${encodeURIComponent(businessEmail)}&su=${encodeURIComponent(subject)}&body=${encodeURIComponent(message)}`;
                window.open(gmailUrl, '_blank');
            }

            setFormData({
                name: '',
                phone: '',
                description: ''
            });
            setTouched({});
            setErrors({});
            alert('בקשתך נשלחה בהצלחה! נחזור אליך בהקדם.');
        } catch (error) {
            alert('אירעה שגיאה בשליחת הטופס. אנא נסה שוב.');
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <div className="min-h-screen bg-[#f8f5f0] py-8 sm:py-12 px-4 sm:px-6 lg:px-8" dir="rtl">
            <div className="max-w-2xl mx-auto">
                <div className="text-center mb-8 sm:mb-12">
                    <h1 className="text-3xl sm:text-4xl font-bold text-gray-900 border-b-4 border-[#c9a049] pb-4 mb-6 inline-block">
                        הזמנה בהתאמה אישית
                    </h1>
                    <p className="text-base sm:text-lg text-gray-600">
                        נשמח לעזור לך ליצור את המוצר המושלם עבורך
                    </p>
                </div>

                <div className="bg-white/70 backdrop-blur-sm rounded-lg border border-[#c9a049]/20 p-6 sm:p-8 transition-all duration-300 hover:shadow-lg">
                    <form onSubmit={handleSubmit} className="space-y-6">
                        <Input
                            label="שם המזמין"
                            error={errors.name}
                            register={{
                                name: "name",
                                value: formData.name,
                                onChange: handleChange,
                                onBlur: () => handleBlur('name')
                            }}
                            placeholder="הכנס את שמך"
                        />

                        <Input
                            label="מספר טלפון"
                            error={errors.phone}
                            register={{
                                name: "phone",
                                value: formData.phone,
                                onChange: handleChange,
                                onBlur: () => handleBlur('phone')
                            }}
                            placeholder="הכנס את מספר הטלפון שלך"
                            type="tel"
                        />

                        <div className="mb-6">
                            <label className="block text-gray-800 font-bold mb-2 text-right">
                                פרטי הבקשה<span className="text-red-500 mr-1">*</span>
                            </label>
                            <textarea
                                name="description"
                                value={formData.description}
                                onChange={handleChange}
                                onBlur={() => handleBlur('description')}
                                placeholder="הכנס תיאור מפורט של המוצר"
                                className={`w-full p-4 border rounded-lg h-32 transition-all duration-300 placeholder-gray-400 bg-white/80 resize-none
                                    ${errors.description
                                    ? 'border-red-500 focus:ring-2 focus:ring-red-200'
                                    : 'border-[#c9a049]/20 focus:border-[#c9a049] focus:ring-2 focus:ring-[#c9a049]/20'
                                } hover:border-[#c9a049]/40`}
                                dir="rtl"
                            />
                            {errors.description && (
                                <p className="mt-2 text-sm text-red-500 text-right">{errors.description}</p>
                            )}
                        </div>

                        <button
                            type="submit"
                            disabled={isSubmitting}
                            className={`w-full py-4 px-8 rounded-lg text-white text-lg font-medium transition-all duration-300 
                                ${isSubmitting
                                ? 'bg-[#c9a049]/70 cursor-not-allowed'
                                : 'bg-[#c9a049] hover:bg-[#b88f3d] hover:shadow-[0_4px_20px_rgba(201,160,73,0.3)]'
                            }`}
                        >
                            {isSubmitting ? 'שולח...' : 'שלח לנו את פרטי הבקשה'}
                        </button>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default PersonalCustomization;