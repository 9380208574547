import React, { useState } from 'react';
import { Link } from "react-router-dom";
import emailjs from 'emailjs-com';

const Footer = () => {
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();

        const templateParams = {
            message: `היי, אני מעוניין להצטרף לעדכונים שלכם על מבצעים. המייל שלי הוא: ${email}`,
            user_email: email
        };

        emailjs.send('service_zlkbd6l', 'template_49mvlpl', templateParams, 'DKL2QLy_yiHcQ53pZ')
            .then((response) => {
                console.log('SUCCESS!', response.status, response.text);
                setMessage('המייל נוסף בהצלחה');
                setTimeout(() => setMessage(''), 3000);
                setEmail('');
            }, (error) => {
                console.log('FAILED...', error);
                setMessage('שגיאה בשליחת המייל');
                setTimeout(() => setMessage(''), 3000);
            });
    };

    const footerLinks = {
        קיצורים: [
            { name: 'בית', path: '/' },
            { name: 'עגלת קניות', path: '/orders' },
            { name: 'בואו נדבר', path: '/contactUs' }
        ],
        מידע: [
            { name: 'הצהרת נגישות', path: '/accessibility' },
            { name: 'הצהרת פרטיות', path: '/privacy' },
            { name: 'תנאי שימוש', path: '/terms' }
        ],
        'רשתות חברתיות': [
            { name: 'Instagram', path: 'https://www.instagram.com/pumawoodworking/', external: true },
            { name: 'Facebook', path: 'https://www.facebook.com/profile.php?id=61556785871965', external: true }
        ]
    };

    return (
        <footer className="bg-gray-900 text-gray-300 pt-16 pb-6">
            {/* ניוזלטר */}
            <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8 mb-12">
                <div className="text-center">
                    <h2 className="text-2xl font-bold mb-4">הצטרפו לניוזלטר שלנו</h2>
                    <p className="text-gray-400 mb-8">קבלו עדכונים על המבצעים הכי חמים ישירות למייל</p>

                    <form onSubmit={handleSubmit} className="max-w-md mx-auto">
                        <div className="flex flex-col sm:flex-row gap-3 justify-center">
                            <button
                                type="submit"
                                className="bg-amber-400 text-gray-900 py-2 px-6 rounded-lg font-medium hover:bg-amber-300 transition-colors duration-200"
                            >
                                הרשמה
                            </button>
                            {message && (
                                <p className="mt-3 text-sm text-amber-400">{message}</p>
                            )}
                            <input
                                type="email"
                                placeholder="האימייל שלך"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                className="flex-grow py-2 px-4 bg-gray-800 rounded-lg focus:outline-none focus:ring-2 focus:ring-amber-400 text-right"
                                required
                            />
                        </div>
                    </form>
                </div>
            </div>

            {/* לינקים */}
            <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 text-right">
                    {Object.entries(footerLinks).map(([title, links]) => (
                        <div key={title} className="mb-8">
                            <h3 className="text-lg font-semibold mb-4 text-white">{title}</h3>
                            <ul className="space-y-3">
                                {links.map((link) => (
                                    <li key={link.path}>
                                        {link.external ? (
                                            <a
                                                href={link.path}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className="hover:text-amber-400 transition-colors duration-200"
                                            >
                                                {link.name}
                                            </a>
                                        ) : (
                                            <Link
                                                to={link.path}
                                                className="hover:text-amber-400 transition-colors duration-200"
                                            >
                                                {link.name}
                                            </Link>
                                        )}
                                    </li>
                                ))}
                            </ul>
                        </div>
                    ))}
                </div>
            </div>

            {/* קופירייט */}
            <div className="border-t border-gray-800 mt-12 pt-8">
                <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8 text-center text-sm text-gray-400">
                    <p>Hazan © Puma {new Date().getFullYear()}</p>
                </div>
            </div>
        </footer>
    );
};

export default Footer;