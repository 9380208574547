import './App.css';
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navbar from "./Components/Navbar/Navbar";
import Home from "./Pages/Home/Home";
import ScrollToTop from "./Pages/Home/ScrollToTop";
import ContactUs from "./Pages/ContactUs/ContactUs";
import Orders from "./Pages/Orders/Orders";
import { CartProvider } from "./Context/CartContext";
import PergolasAndDecks from "./Components/PergolasAndDecks/PergolasAndDecks";
import HomeFurniture from "./Components/HomeFurniture/HomeFurniture";
import GardenFurniture from "./Components/GardenFurniture/GardenFurniture";
import Offers from "./Components/Offers/Offers";
import WhatsAppFloatingButton from "./Components/WhatsAppFloatingButton/WhatsAppFloatingButton";
import PersonalCustomization from "./Pages/PersonalCustomization/PersonalCustomization";
import ProductPage from "./Pages/ProjectDetails/ProductPage";
import Privacy from "./Pages/Statements/Privacy";
import Terms from "./Pages/Statements/Terms";
import Accessibility from "./Pages/Statements/Accessibility";
import Footer from "./Components/Footer/Footer";


function App() {
    return (
        <CartProvider>
            <Router>
                <div>
                    <Navbar />
                    <Routes>
                        <Route path="/" element={<Home />} />
                        <Route path="/productPage" element={<ProductPage />} />
                        <Route path="/homeFurniture" element={<HomeFurniture />} />
                        <Route path="/gardenFurniture" element={<GardenFurniture />} />
                        <Route path="/pergolasAndDecks" element={<PergolasAndDecks />} />
                        <Route path="/contactUs" element={<ContactUs />} />
                        <Route path="/orders" element={<Orders />} />
                        <Route path="/offers" element={<Offers />} />
                        <Route path="/personalCustomization" element={<PersonalCustomization />} />
                        <Route path="/product-page/:id" element={<ProductPage />} />
                        <Route path="/terms" element={<Terms />} />
                        <Route path="/privacy" element={<Privacy />} />
                        <Route path="/accessibility" element={<Accessibility />} />
                    </Routes>
                    <WhatsAppFloatingButton />
                    <ScrollToTop />
<Footer/>
                </div>
            </Router>
        </CartProvider>
    );
}

export default App;