import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { CartContext } from "../../Context/CartContext";

import package1Image from '../../images/package-table-swing.jpg';
import package3Image from '../../images/package-garden.webp';
import swing5 from "../../images/swing/swing5.jpg";
import table from "../../images/table/table.jpg";
import bench1 from "../../images/bench/bench2.jpg";
import bench2 from "../../images/bench/bench1.jpg";

const projects = [
    {
        id: 21,
        title: 'חבילת פינות ישיבה',
        mainImage: package1Image,
        galleryImages: [swing5, table, bench1],
        sizes: ['2 ספסלים זוגיים, שולחן פינת ישיבה ונדנדה'],
        woodTypes: ['אורן'],
        price: "---"
    },
    {
        id: 22,
        title: 'חבילת הגינה המשתלמת',
        mainImage: package3Image,
        galleryImages: [table, bench1, bench2],
        sizes: ['ספסל זוגי, ספסל יחיד, שולחן פינת ישיבה'],
        woodTypes: ['אורן'],
        price: "---"
    }
];

export { projects };

const Offers = () => {
    const { addToItem } = useContext(CartContext);
    const navigate = useNavigate();

    const handleClick = (project) => {
        addToItem(project);
        navigate(`/product-page/${project.id}`, { state: { project } });
    };

    return (
        <div className="flex justify-center items-center p-6 sm:p-10 bg-[#f5f0ec] font-rubik" dir="rtl">
            <div className="w-full max-w-6xl">
                <h1 className="text-5xl font-bold text-gray-900 text-center mb-12 font-['Amatic_SC']">
                    חבילות בשבילך
                </h1>

                <div className="grid grid-cols-1 md:grid-cols-2 gap-6 sm:gap-8">
                    {projects.map(project => (
                        <div
                            key={project.id}
                            onClick={() => handleClick(project)}
                            className="bg-white rounded-2xl shadow-md hover:shadow-xl transition-all duration-300
                       hover:-translate-y-2 cursor-pointer overflow-hidden text-center p-4 sm:p-6"
                        >
                            <img
                                src={project.mainImage}
                                alt={project.title}
                                className="w-full h-48 sm:h-64 object-cover rounded-lg mb-4"
                            />
                            <h3 className="text-3xl sm:text-4xl text-gray-800 mb-4 font-amatic">
                                {project.title}
                            </h3>
                            <p className="text-sm sm:text-base text-gray-600">
                                בחרו את החבילה הכי משתלמת לגינה שלכם
                            </p>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Offers;