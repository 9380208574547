import React from 'react';

const AddToCartPopup = ({ item, onClose, onContinueShopping, onProceedToCheckout }) => {
    return (
        <div
            className="fixed inset-0 bg-black bg-opacity-60 flex justify-center items-center z-50 transition-all duration-300"
            onClick={onClose}
        >
            <div
                className="bg-white bg-opacity-85 p-6 rounded-lg text-center w-[90%] max-w-md shadow-lg translate-x-0 transition-transform duration-300"
                onClick={(e) => e.stopPropagation()}
            >
                {/* Close Button */}
                <button
                    className="absolute top-2 left-4 text-4xl font-bold text-gray-600 hover:text-gray-800 transition-colors"
                    onClick={onClose}
                    aria-label="סגור"
                >
                    &times;
                </button>

                {/* Title */}
                <h2 className="text-xl font-semibold mb-6">
                    המוצר התווסף בהצלחה לעגלת הקניות
                </h2>

                {/* Item Details */}
                <div className="flex flex-col md:flex-row gap-4 mb-6 items-center md:items-start">
                    <img
                        src={item.image}
                        alt={item.title}
                        className="w-24 h-24 rounded-lg object-cover"
                    />
                    <div className="text-right space-y-2">
                        <p><strong>פריט:</strong> {item.title}</p>
                        <p><strong>גודל:</strong> {item.size}</p>
                        <p><strong>צבע:</strong> {item.color.name}</p>
                        <p><strong>סוג עץ:</strong> {item.woodType}</p>
                        <p><strong>מחיר:</strong> ₪{item.price}</p>
                    </div>
                </div>

                {/* Action Buttons */}
                <div className="flex flex-col md:flex-row gap-3">
                    <button
                        onClick={onProceedToCheckout}
                        className="w-full md:w-1/2 bg-[#4CAF50] hover:bg-[#45A049] text-white py-3 px-6 rounded transition-colors"
                    >
                        מעבר לעגלת קניות
                    </button>
                    <button
                        onClick={onContinueShopping}
                        className="w-full md:w-1/2 bg-[#cfa54b] hover:bg-[#daa332] text-white py-3 px-6 rounded transition-colors"
                    >
                        להמשך קניה
                    </button>
                </div>
            </div>
        </div>
    );
};

export default AddToCartPopup;