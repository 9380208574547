import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp, faFacebook, faInstagram } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope, faPhone } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

const ContactCard = ({ icon, title, text, subText, onClick }) => (
    <div
        onClick={onClick}
        className="group flex flex-col items-center p-6 bg-white rounded-xl shadow-lg hover:shadow-2xl transition-all duration-300 cursor-pointer hover:-translate-y-1 space-y-4"
        role="button"
        tabIndex={0}
    >
        <div className="p-4 bg-gray-50 rounded-full group-hover:bg-gray-100 transition-colors duration-300">
            <FontAwesomeIcon
                icon={icon}
                className="text-3xl text-gray-700 group-hover:text-gray-900"
                aria-label={title}
            />
        </div>
        <h3 className="text-xl font-semibold text-gray-800">{title}</h3>
        <p className="text-gray-600 text-center">{text}</p>
        {subText && <p className="text-sm text-gray-500">{subText}</p>}
    </div>
);

const SocialIcon = ({ icon, to }) => (
    <Link
        to={to}
        target="_blank"
        rel="noopener noreferrer"
        className="p-3 bg-gray-50 rounded-full hover:bg-gray-100 transition-colors duration-300"
    >
        <FontAwesomeIcon
            icon={icon}
            className="text-2xl text-gray-700 hover:text-gray-900 transition-colors duration-300"
        />
    </Link>
);

const ContactUs = () => {
    const whatsappNumber = '+972529022532';
    const prefilledMessage = 'שלום, אני מעוניין ב';
    const email = 'bwdwtz417@gmail.com';
    const emailSubject = 'פנייה מהאתר';
    const emailBody = 'שלום, אני מעוניין ב';
    const phoneNumber = '052-902-2532';

    const openWhatsApp = () => {
        window.open(`https://wa.me/${whatsappNumber}?text=${encodeURIComponent(prefilledMessage)}`, '_blank');
    };

    const openEmail = () => {
        window.open(`https://mail.google.com/mail/u/0/?view=cm&fs=1&to=${encodeURIComponent(email)}&su=${encodeURIComponent(emailSubject)}&body=${encodeURIComponent(emailBody)}`, '_blank');
    };

    const openPhone = () => {
        window.open(`tel:${phoneNumber}`, '_blank');
    };

    return (
        <div className="min-h-screen bg-gradient-to-b from-gray-50 to-white py-12 px-4 sm:px-6 lg:px-8">
            <div className="max-w-7xl mx-auto">
                <div className="text-center mb-12 sm:mb-16">
                    <h1 className="text-5xl font-bold text-gray-900 text-center mb-8 font-['Amatic_SC']">
                        דברו איתנו
                    </h1>
                    <p className="text-base sm:text-lg text-gray-600">
                        נשמח לעמוד לשירותכם בכל שאלה או בקשה
                    </p>
                </div>

                <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mb-16">
                    <ContactCard
                        icon={faWhatsapp}
                        title="WhatsApp"
                        text="לחצו, ומיד תעברו לשוחח איתנו"
                        onClick={openWhatsApp}
                    />
                    <ContactCard
                        icon={faPhone}
                        title="טלפון"
                        text={phoneNumber}
                        subText="צוריאל"
                        onClick={openPhone}
                    />
                    <ContactCard
                        icon={faEnvelope}
                        title="אימייל"
                        text={email}
                        onClick={openEmail}
                    />
                </div>

                <div className="bg-white rounded-xl shadow-lg p-8 max-w-2xl mx-auto">
                    <h2 className="text-2xl font-semibold text-center text-gray-800 mb-8">
                        עקבו אחרינו
                    </h2>
                    <div className="flex justify-center gap-6">
                        <SocialIcon
                            icon={faFacebook}
                            to="https://www.facebook.com/profile.php?id=61556785871965"
                        />
                        <SocialIcon
                            icon={faInstagram}
                            to="https://www.instagram.com/pumawoodworking/"
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ContactUs;