import React, { useContext } from 'react';
import { CartContext } from '../../Context/CartContext';
import { Link, useNavigate } from 'react-router-dom';
import { ChevronRight } from 'lucide-react';

function Orders() {
    const { cart, removeFromCart } = useContext(CartContext);
    const navigate = useNavigate();

    const handleRemove = (itemId) => {
        removeFromCart(itemId);
    };

    const sendOrder = () => {
        const businessWhatsAppNumber = '+972529022532';
        const businessEmail = 'bwdwtz417@gmail.com';

        const orderDetails = cart.map((item) => `
תיאור: ${item.title}
גודל: ${item.size}
צבע: ${item.color.name}
סוג עץ: ${item.woodType}
    `).join('\n\n');

        const orderSummary = `כמות הפריטים בעגלה: ${cart.length}`;
        const message = `הזמנה חדשה:\n\n${orderDetails}\n\n${orderSummary}`;

        if (window.matchMedia("(max-width: 767px)").matches) {
            window.open(`https://wa.me/${businessWhatsAppNumber}?text=${encodeURIComponent(message)}`, '_blank');
        } else {
            window.open(`https://mail.google.com/mail/u/0/?view=cm&fs=1&to=${businessEmail}&su=הזמנה חדשה&body=${encodeURIComponent(message)}`, '_blank');
        }
    };

    const OrderItem = ({ item, onRemove }) => (
        <div className="bg-white/70 backdrop-blur-sm rounded-lg overflow-hidden transition-all duration-300 hover:shadow-lg border border-[#c9a049]/20 hover:border-[#c9a049]/40">
            <div className="relative group">
                <img src={item.image} alt={item.title} className="w-full h-64 object-cover transition-transform duration-300 group-hover:scale-105" />
                <div className="absolute inset-0 bg-gradient-to-t from-black/20 to-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-300" />
            </div>
            <div className="p-6 bg-gradient-to-b from-[#c9a049]/5 to-transparent">
                <h2 className="text-xl font-bold text-gray-800 mb-4 border-r-4 border-[#c9a049] pr-3">{item.title}</h2>
                <div className="space-y-4">
                    <div className="flex items-center gap-3 text-gray-700">
                        <span className="text-sm font-medium">מידות:</span>
                        <span className="text-base bg-gray-50 px-3 py-1 rounded-full">{item.size}</span>
                    </div>
                    <div className="flex items-center gap-3 text-gray-700">
                        <span className="text-sm font-medium">צבע:</span>
                        <div className="flex items-center gap-2 bg-gray-50 px-3 py-1 rounded-full">
                            <span className="w-4 h-4 rounded-full border border-gray-200" style={{ backgroundColor: item.color?.code || 'transparent' }} />
                            <span className="text-base">{item.color?.name || 'ללא'}</span>
                        </div>
                    </div>
                    <div className="flex items-center gap-3 text-gray-700">
                        <span className="text-sm font-medium">סוג עץ:</span>
                        <span className="text-base bg-gray-50 px-3 py-1 rounded-full">{item.woodType}</span>
                    </div>
                    <button
                        className="w-full px-4 py-2 mt-4 text-red-600 border border-red-600 rounded-lg hover:bg-red-50 transition-colors"
                        onClick={() => handleRemove(item.id)}
                        role="button"
                        tabIndex="0"
                        aria-label={`הסר ${item.title}`}
                    >
                        הסר
                    </button>
                </div>
            </div>
        </div>
    );

    return (
        <div className="min-h-screen bg-[#f8f5f0] py-12 px-4 sm:px-6 lg:px-8" dir="rtl">
            <div className="max-w-7xl mx-auto">
                <div className="flex flex-col gap-4 mb-12">
                    <h1 className="text-4xl font-bold text-gray-900 border-r-4 border-[#c9a049] pr-4">
                        העגלה שלך
                    </h1>
                    <Link to="/" className="flex items-center gap-2 text-[#c9a049] hover:text-[#b88f3d] transition-colors duration-300 group w-fit">
                        <ChevronRight className="w-5 h-5 transition-transform group-hover:translate-x-1" />
                        <span className="text-base">חזרה לקטלוג</span>
                    </Link>
                </div>

                {cart.length === 0 ? (
                    <div className="text-center py-16 bg-white rounded-lg border border-[#c9a049]/20">
                        <p className="text-2xl font-medium text-gray-600 mb-8">העגלה שלך ריקה</p>
                        <Link to="/"
                              className="inline-block px-8 py-3 bg-[#c9a049] text-white text-base font-medium rounded-lg hover:bg-[#b88f3d] transition-all duration-300 hover:shadow-[0_4px_20px_rgba(201,160,73,0.3)]">
                            המשך לקטלוג המוצרים
                        </Link>
                    </div>
                ) : (
                    <>
                        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 mb-12">
                            {cart.map((item, index) => (
                                <OrderItem key={`${item.id}-${index}`} item={item} onRemove={handleRemove} />
                            ))}
                        </div>

                        <div className="max-w-2xl mx-auto">
                            <div className="bg-white rounded-lg border border-[#c9a049]/20 p-8">
                                <div className="flex items-center justify-between border-b border-[#c9a049]/10 pb-6 mb-6">
                                    <h2 className="text-2xl font-bold text-gray-900">סיכום הזמנה</h2>
                                    <div className="flex items-center gap-2 bg-[#c9a049]/10 px-4 py-2 rounded-lg">
                                        <span className="text-gray-600">כמות פריטים:</span>
                                        <span className="text-xl font-bold text-[#c9a049]">{cart.length}</span>
                                    </div>
                                </div>
                                <p className="text-base text-gray-600 mb-8 text-center px-4">
                                    בלחיצה על "שלח הזמנה" מיד תעבור להתכתבות עם בית העסק ולהצעת מחיר.
                                </p>
                                <div className="flex justify-center">
                                    <button
                                        onClick={sendOrder}
                                        className="px-10 py-4 bg-[#c9a049] text-white text-lg font-medium rounded-lg hover:bg-[#b88f3d] transition-all duration-300 hover:shadow-[0_4px_20px_rgba(201,160,73,0.3)] focus:outline-none focus:ring-2 focus:ring-[#c9a049] focus:ring-offset-2"
                                    >
                                        שלח הזמנה
                                    </button>
                                </div>
                            </div>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
}

export default Orders;