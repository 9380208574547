import React, { useRef } from 'react';
import '../../App.css';

import HomeFurniture from "../../Components/HomeFurniture/HomeFurniture";
import Footer from "../../Components/Footer/Footer";
import HeroSection from "../../Components/Hero-Section/Hero-Section";
import AboutUs from "../../Components/AboutUs/AboutUs";
import PergolasAndDecks from "../../Components/PergolasAndDecks/PergolasAndDecks";
import GardenFurniture from "../../Components/GardenFurniture/GardenFurniture";
import ContactUs from "../ContactUs/ContactUs";
import Offers from "../../Components/Offers/Offers";
import OurProjects from "../../Components/OurProjects/OurProjects";
import BgPic2 from "../../Components/Backgrounds/BgPic2";
import BgPic1 from "../../Components/Backgrounds/BgPic1";


function Home() {
    const PergolasAndDecksRef = useRef(null);
    const cardsRef = useRef(null);
    const middelRef = useRef(null);
    const mainRef = useRef(null);
    const supplierRef = useRef(null);
    const specialOffersRef = useRef(null);
    const footerRef = useRef(null);
    const contactUsRef = useRef(null);
    const ourProjects = useRef(null);

    const scrollToSection = (ref) => {
        ref.current.scrollIntoView({ behavior: 'smooth' });
    };

    return (
        <>
            <HeroSection scrollToSection={scrollToSection} PergolasAndDecksRef={PergolasAndDecksRef} cardsRef={cardsRef}
                         middelRef={middelRef} mainRef={mainRef} supplierRef={supplierRef}
                         specialOffersRef={specialOffersRef} ourProjects={ourProjects} contactUsRef={contactUsRef} footerRef={footerRef}/>
            <div ref={specialOffersRef}>
                <AboutUs/>
            </div>
            <div ref={PergolasAndDecksRef}>
                <PergolasAndDecks/>
            </div>
            <div ref={mainRef}>
                <BgPic2/>
            </div>
            <div ref={cardsRef}>
                <HomeFurniture/>
            </div>
            <div ref={supplierRef}>
                <BgPic1/>
            </div>
            <div ref={middelRef}>
                <GardenFurniture/>
            </div>
            <Offers/>
            <div ref={ourProjects}>
            <OurProjects/>
            </div>
            <div ref={contactUsRef}>
                <ContactUs/>
            </div>

        </>
    );
}

export default Home;
