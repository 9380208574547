import React, { useState } from 'react';
import { ChevronRight, ChevronLeft } from 'lucide-react';


import gardenPlanters from '../../images/garden-planters/garden-planters.jpg';
import gardenPlanters1 from '../../images/garden-planters/garden-planters1.jpg';
import gardenPlanters6 from '../../images/garden-planters/garden-planters3.jpg';
import gardenPlanters8 from '../../images/types/storge-box.jpg';
import gardenPlanters7 from '../../images/types/storge-box1.jpg';
import playHouse2 from "../../images/playHouse/playHouse1.jpg";

const OurProjects = () => {
    const [currentIndex, setCurrentIndex] = useState(0);

    const images = [
        gardenPlanters,
        gardenPlanters1,
        gardenPlanters6,
        gardenPlanters8,
        gardenPlanters7,
        playHouse2,
    ];

    const titles = [
        "אדניות לגינה",
        "אדניות לגינה",
        "גילוף בעיצוב אישי",
        "ארגז איחסון",
        "ארגז איחסון",
        "בית עץ"
    ];

    const nextImage = () => {
        setCurrentIndex((prev) => (prev + 1) % images.length);
    };

    const prevImage = () => {
        setCurrentIndex((prev) => (prev - 1 + images.length) % images.length);
    };

    const handleKeyDown = (e) => {
        if (e.key === 'ArrowLeft') nextImage();
        if (e.key === 'ArrowRight') prevImage();
    };

    return (
        <div
            className="container mx-auto px-4 py-8 rtl"
            dir="rtl"
            tabIndex={0}
            onKeyDown={handleKeyDown}
        >
            <h1 className="text-5xl font-bold text-gray-900 text-center mb-12 font-['Amatic_SC']">
                הפרויקטים שלנו
            </h1>

            {/* Main Image Section */}
            <div className="relative mb-6 max-w-3xl mx-auto">
                <div className="aspect-w-16 aspect-h-9 rounded-lg overflow-hidden">
                    <img
                        src={images[currentIndex]}
                        alt={titles[currentIndex]}
                        className="w-full h-[300px] object-cover rounded-lg"
                    />
                    <div className="absolute inset-0 bg-black bg-opacity-20">
                        <div className="absolute bottom-4 right-4 text-white text-2xl font-semibold">
                            {titles[currentIndex]}
                        </div>
                    </div>
                </div>

                {/* Navigation Arrows */}
                <button
                    onClick={prevImage}
                    className="absolute left-4 top-1/2 -translate-y-1/2 bg-white bg-opacity-50 p-2 rounded-full hover:bg-opacity-75 transition-all"
                >
                    <ChevronLeft size={24}/>
                </button>
                <button
                    onClick={nextImage}
                    className="absolute right-4 top-1/2 -translate-y-1/2 bg-white bg-opacity-50 p-2 rounded-full hover:bg-opacity-75 transition-all"
                >
                    <ChevronRight size={24}/>
                </button>
            </div>

            {/* Thumbnails Gallery */}
            <div className="grid grid-cols-3 sm:grid-cols-4 md:grid-cols-6 gap-4 mt-4 max-w-3xl mx-auto">
                {images.map((image, index) => (
                    <div
                        key={index}
                        onClick={() => setCurrentIndex(index)}
                        className={`
              cursor-pointer rounded-lg overflow-hidden
              ${currentIndex === index ? 'ring-2 ring-blue-500' : ''}
              transition-all hover:opacity-80
            `}
                    >
                        <img
                            src={image}
                            alt={titles[index]}
                            className="w-full h-20 object-cover"
                        />
                    </div>
                ))}
            </div>
        </div>
    );
};

export default OurProjects;