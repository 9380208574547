import React, { useState, useContext } from 'react';
import { CartContext } from '../../Context/CartContext';
import { useNavigate, useLocation } from "react-router-dom";
import { ChevronRight } from 'lucide-react';
import AddToCartPopup from '../../Components/AddToCartPopup/AddToCartPopup';

const ProductPage = () => {
    const { state } = useLocation();
    const { project } = state;
    const { addToCart } = useContext(CartContext);
    const navigate = useNavigate();

    const [selectedSize, setSelectedSize] = useState(project.sizes[0]);
    const [selectedColor, setSelectedColor] = useState('ללא');
    const [selectedWoodType, setSelectedWoodType] = useState(project.woodTypes[0]);
    const [mainImg, setMainImg] = useState(project.mainImage);
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [currentImage, setCurrentImage] = useState(null);
    const [popupVisible, setPopupVisible] = useState(false);
    const [addedItem, setAddedItem] = useState(null);

    const colors = [
        { name: 'ללא', code: '#dfdede' },
        { name: 'לבן', code: '#fff' },
        { name: 'אגוז', code: '#9a6543' },
        { name: 'אלון', code: '#7b5136' }
    ];

    // Original handlers remain the same
    const handleSizeChange = (e) => setSelectedSize(e.target.value);
    const handleColorChange = (color) => setSelectedColor(color.name);
    const handleWoodTypeChange = (e) => setSelectedWoodType(e.target.value);
    const calculatePrice = () => project.basePrice;
    const goBack = () => navigate(-1);
    const openModal = (image) => {
        setCurrentImage(image);
        setModalOpen(true);
    };
    const closeModal = () => {
        setModalOpen(false);
        setCurrentImage(null);
    };
    const handleClosePopup = () => setPopupVisible(false);
    const handleContinueShopping = () => setPopupVisible(false);
    const handleProceedToCheckout = () => {
        setPopupVisible(false);
        navigate('/orders');
    };

    const handleAddToCart = () => {
        const newItem = {
            id: `${project.title}-${selectedSize}-${selectedColor}-${selectedWoodType}`,
            title: project.title,
            size: selectedSize,
            color: colors.find(c => c.name === selectedColor) || { name: 'ללא', code: 'none' },
            woodType: selectedWoodType,
            price: calculatePrice(),
            image: mainImg,
        };
        addToCart(newItem);
        setAddedItem(newItem);
        setShowSuccessMessage(true);
        setTimeout(() => {
            setShowSuccessMessage(false);
            setPopupVisible(true);
        }, 1000);
    };

    return (
        <div className="min-h-screen bg-[#f8f5f0] py-6 sm:py-12 px-3 sm:px-6 lg:px-8" dir="rtl">
            <div className="max-w-7xl mx-auto">
                {/* Header - מותאם למובייל */}
                <div className="flex flex-col gap-3 sm:gap-4 mb-6 sm:mb-8">
                    <h1 className="text-2xl sm:text-4xl font-bold text-gray-900 border-r-4 border-[#c9a049] pr-4">
                        {project.title}
                    </h1>
                    <button
                        onClick={goBack}
                        className="flex items-center gap-2 text-[#c9a049] hover:text-[#b88f3d] transition-colors duration-300 group w-fit text-sm sm:text-base"
                    >
                        <ChevronRight className="w-4 h-4 sm:w-5 sm:h-5 transition-transform group-hover:translate-x-1" />
                        <span>חזרה לקטלוג</span>
                    </button>
                </div>

                <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 sm:gap-12">
                    {/* Gallery - מותאם למובייל */}
                    <div className="space-y-3 sm:space-y-4">
                        <div className="bg-white/70 backdrop-blur-sm rounded-lg overflow-hidden border border-[#c9a049]/20 hover:border-[#c9a049]/40 transition-all">
                            <img
                                src={mainImg}
                                alt={project.title}
                                className="w-full h-[300px] sm:h-[500px] object-cover cursor-pointer transition-transform duration-300 hover:scale-105"
                                onClick={() => openModal(mainImg)}
                            />
                        </div>
                        <div className="grid grid-cols-4 gap-2 sm:gap-4">
                            {project.galleryImages.map((image, index) => (
                                <button
                                    key={index}
                                    className={`bg-white/70 backdrop-blur-sm rounded-lg overflow-hidden border transition-all
                                        ${mainImg === image
                                        ? 'border-[#c9a049] shadow-md'
                                        : 'border-[#c9a049]/20 hover:border-[#c9a049]/40'
                                    }`}
                                    onClick={() => setMainImg(image)}
                                >
                                    <img
                                        src={image}
                                        alt={`${project.title} ${index + 1}`}
                                        className="w-full h-16 sm:h-24 object-cover transition-transform duration-300 hover:scale-105"
                                    />
                                </button>
                            ))}
                        </div>
                    </div>

                    {/* Product Details - מותאם למובייל */}
                    <div className="bg-white/70 backdrop-blur-sm rounded-lg border border-[#c9a049]/20 p-4 sm:p-8">
                        <div className="space-y-4 sm:space-y-6">
                            {/* Size Selector */}
                            <div className="space-y-2">
                                <label className="text-sm font-medium text-gray-700">גודל:</label>
                                <select
                                    value={selectedSize}
                                    onChange={handleSizeChange}
                                    className="w-full px-3 sm:px-4 py-2 text-sm sm:text-base rounded-lg border border-gray-200 focus:border-[#c9a049] focus:ring-2 focus:ring-[#c9a049]/20 transition-colors"
                                >
                                    {project.sizes.map((size, index) => (
                                        <option key={index} value={size}>{size}</option>
                                    ))}
                                </select>
                            </div>

                            {/* Color Selector */}
                            <div className="space-y-2">
                                <label className="text-sm font-medium text-gray-700">צבע:</label>
                                <div className="grid grid-cols-2 gap-2 sm:gap-3">
                                    {colors.map((color, index) => (
                                        <button
                                            key={index}
                                            onClick={() => handleColorChange(color)}
                                            className={`flex items-center gap-2 px-3 sm:px-4 py-2 rounded-lg border transition-all text-sm sm:text-base
                                                ${selectedColor === color.name
                                                ? 'border-[#c9a049] bg-[#c9a049]/5'
                                                : 'border-gray-200 hover:border-[#c9a049]/40'
                                            }`}
                                        >
                                            <span
                                                className="w-4 h-4 sm:w-6 sm:h-6 rounded-full border border-gray-200"
                                                style={{ backgroundColor: color.code }}
                                            />
                                            <span className="text-gray-700">{color.name}</span>
                                        </button>
                                    ))}
                                </div>
                            </div>

                            {/* Wood Type Selector */}
                            <div className="space-y-2">
                                <label className="text-sm font-medium text-gray-700">סוג עץ:</label>
                                <select
                                    value={selectedWoodType}
                                    onChange={handleWoodTypeChange}
                                    className="w-full px-3 sm:px-4 py-2 text-sm sm:text-base rounded-lg border border-gray-200 focus:border-[#c9a049] focus:ring-2 focus:ring-[#c9a049]/20 transition-colors"
                                >
                                    {project.woodTypes.map((woodType, index) => (
                                        <option key={index} value={woodType}>{woodType}</option>
                                    ))}
                                </select>
                            </div>

                            {/* Price and Add to Cart */}
                            <div className="border-t border-[#c9a049]/10 pt-4 sm:pt-6 mt-4 sm:mt-6">
                                <div className="flex justify-between items-center mb-4 sm:mb-6">
                                    <span className="text-base sm:text-lg font-medium text-gray-700">מחיר:</span>
                                    <span className="text-xl sm:text-2xl font-bold text-[#c9a049]">₪{project.price}</span>
                                </div>

                                <button
                                    onClick={handleAddToCart}
                                    className="w-full px-6 sm:px-8 py-3 sm:py-4 bg-[#c9a049] text-white text-base sm:text-lg font-medium rounded-lg hover:bg-[#b88f3d] transition-all duration-300 hover:shadow-[0_4px_20px_rgba(201,160,73,0.3)] focus:outline-none focus:ring-2 focus:ring-[#c9a049] focus:ring-offset-2"
                                >
                                    הוסף לסל
                                </button>

                                {showSuccessMessage && (
                                    <p className="text-center text-green-600 mt-3 sm:mt-4 text-sm sm:text-base font-medium">
                                        הפריט נוסף לסל!
                                    </p>
                                )}

                                <p className="text-xs sm:text-sm text-gray-500 text-center mt-3 sm:mt-4">
                                    *כל המחירים הנקובים לא כוללים מע"מ וצביעה
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Modal - מותאם למובייל */}
            {modalOpen && (
                <div
                    className="fixed inset-0 bg-black/70 backdrop-blur-sm flex items-center justify-center z-50 p-2 sm:p-4"
                    onClick={closeModal}
                >
                    <div className="relative max-w-screen-lg w-full">
                        <button
                            onClick={closeModal}
                            className="absolute top-2 sm:top-4 right-2 sm:right-4 text-white bg-black/50 rounded-full p-1.5 sm:p-2 hover:bg-black/70 transition-colors"
                        >
                            ✕
                        </button>
                        <img
                            src={currentImage}
                            alt="תמונה מוגדלת"
                            className="w-full h-auto rounded-lg"
                        />
                    </div>
                </div>
            )}

            {/* Popup נשאר כמו שהוא כי הוא קומפוננטה נפרדת */}
            {popupVisible && (
                <AddToCartPopup
                    item={addedItem}
                    onClose={handleClosePopup}
                    onContinueShopping={handleContinueShopping}
                    onProceedToCheckout={handleProceedToCheckout}
                />
            )}
        </div>
    );
};

export default ProductPage;