import React from 'react';
import companyImage from '../../images/swing.png';

function AboutUs() {
    return (
        <div className="flex justify-center items-center py-12 px-4 sm:px-6 bg-[#f5f0ec]" dir="rtl">
            <div
                className="flex flex-col md:flex-row items-center max-w-6xl w-full bg-white rounded-lg shadow-md overflow-hidden">
                {/* Image Section */}
                <div className="w-full md:w-1/2 px-6 md:px-0">
                    <img
                        src={companyImage}
                        alt="תמונה של ערסל נדנדה"
                        className="w-full md:w-[60%] md:mr-12 h-auto object-cover"
                    />
                </div>

                {/* Text Section */}
                <div className="w-full md:w-1/2 p-8 md:p-14 text-right">
                    <h1 className="text-4xl text-gray-800 mb-6 font-amatic font-bold">
                        אודותינו
                    </h1>

                    <p className="text-lg text-gray-600 leading-relaxed mb-6">
                        ברוכים הבאים לPuma! אנחנו חברה שמתמחה בפתרונות ריהוט לבית ולגן,
                        עם דגש על איכות, עיצוב וחדשנות. המוצרים שלנו מיוצרים מהחומרים הטובים ביותר
                        וכוללים מגוון רחב של פרויקטים ייחודיים המותאמים לצרכי הלקוחות שלנו.
                    </p>

                    <p className="text-lg text-gray-600 leading-relaxed">
                        אנו מתחייבים להעניק שירות מעולה וחוויית קנייה בלתי נשכחת.
                        אנחנו תמיד כאן כדי לסייע לכם לבחור את הרהיטים המתאימים ביותר לביתכם או לגינתכם.
                        בואו להתרשם ממבחר הפרויקטים שלנו וליהנות מריהוט איכותי ומעוצב!
                    </p>
                </div>
            </div>
        </div>
    );
}

export default AboutUs;